import {Suspense} from 'react'
import PropTypes from 'prop-types'


const SSRSuspense = ({fallback, ...props}) => {
  if (typeof window === 'undefined') return fallback

  return <Suspense fallback={fallback} {...props} />
}
SSRSuspense.propTypes = {
  // Suspense requires fallback
  fallback: PropTypes.node.isRequired,
}

export default SSRSuspense
