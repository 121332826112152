import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../utils/media'
import ContentContainer from './ContentContainer'


const StyledBackground = styled.div`
  position: relative;
  background-attachment: fixed;
  background-image: url(${({mobileBackground}) => mobileBackground});
  background-position: top;
  background-repeat: no-repeat;
  ${typeof window !== 'undefined' ? css`
    background-size: ${window.innerHeight}px;
  ` : ''}
  ${media.up('mobile')(css`
    background-position: center 20%;
    background-size: cover;
    background-image: url(${({desktopBackground}) => desktopBackground});
  `)}
`

const StyledOverlay = styled.div`
  background: linear-gradient(${({theme}) => theme.overlays.homepageGradient});
  padding: 8rem 0 5rem;
  ${media.down('mobile')(css`
    padding-bottom: 11rem;
  `)}
`

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-bottom: 3rem;
  width: 100%;
  text-align: right;
`

const HomepageBackground = ({desktopBackground, mobileBackground, title, children, ...props}) => {
  return (
    <StyledBackground
        desktopBackground={desktopBackground}
        mobileBackground={mobileBackground}
        {...props}
    >
      <StyledOverlay>
        <ContentContainer>
          <StyledTitleContainer>
            <h1>
              {title}
            </h1>
          </StyledTitleContainer>
        </ContentContainer>
        {children}
      </StyledOverlay>
    </StyledBackground>
  )
}

HomepageBackground.propTypes = {
  title: PropTypes.object.isRequired,
  button: PropTypes.node,
  desktopBackground: PropTypes.string.isRequired,
  mobileBackground: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default HomepageBackground
