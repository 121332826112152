import styled from 'styled-components'


const StyledLine = styled.hr`
  margin: 0.5rem 0;
  border: 0;
  border-radius: ${({theme}) => theme.border.radius.md};
  background-color: ${({theme}) => theme.colors.aux};
  width: 1.5rem;
  height: 4px;
`

const HorizontalLine = ({...props}) => {
  return (
    <StyledLine {...props} />
  )
}

export default HorizontalLine
