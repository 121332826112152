import {useState} from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import {For} from 'babel-plugin-jsx-control-statements'
import {map} from 'lodash-es'
import Appshell from '../components/Appshell'
import Button from '../uikit/Button'
import Flex from '../uikit/Flex'
import GatsbyImage from '../components/GatsbyImage'
import HomepageBackground from '../uikit/HomepageBackground'
import HorizontalLine from '../uikit/HorizontalLine'
import ContentContainer from '../uikit/ContentContainer'
import Text from '../uikit/Text'
import SliderLazy from '../uikit/SliderLazy'
import SSRSuspense from '../uikit/SSRSuspense'


const Homepage = ({data}) => {
  const [control, setContrtol] = useState(null)
  const [activeBook, setActiveBook] = useState(1)
  const {frontmatter} = data?.markdownRemark
  const books = map(data?.books?.nodes, (data, index) => ({index, ...data}))

  return (
    <Appshell title={frontmatter?.title} seo={frontmatter?.seo} initialMenuOpen>
      <HomepageBackground
          desktopBackground={frontmatter.desktopBackground?.publicURL}
          mobileBackground={frontmatter.mobileBackground?.publicURL}
          /* eslint-disable-next-line react/no-danger */
          title={<div dangerouslySetInnerHTML={{__html: frontmatter.subtitle}} />}
      >
        <Flex column>
          <SSRSuspense fallback={<div />}>
            <SliderLazy
                control={control}
                onSwiper={setContrtol}
                onSlideChange={({realIndex}) => {
                  setActiveBook(realIndex)
                }}
            >
              <For each="book" of={books}>
                <Link key={book.id} to={book.frontmatter?.slug}>
                  <GatsbyImage
                      image={book.frontmatter?.image?.childImageSharp?.gatsbyImageData}
                      alt={book.frontmatter?.imageAlt}
                  />
                </Link>
              </For>
            </SliderLazy>
          </SSRSuspense>
          <ContentContainer>
            <h1 className="sr-only">{frontmatter?.title}</h1>
            <Flex column>
              <Text component="h2" textStyle="h1">{books[activeBook].frontmatter?.title}</Text>
              <p>{books[activeBook].frontmatter?.tags}</p>
              <HorizontalLine />
              <p>{books[activeBook].frontmatter?.description}</p>
              <Flex mainAxis="center">
                <Button component={Link} isLink to={books[activeBook].frontmatter?.slug}>
                  Tomášova recenzia
                </Button>
              </Flex>
            </Flex>
          </ContentContainer>
        </Flex>
      </HomepageBackground>
    </Appshell>
  )
}

export const data = graphql`
  query Homepage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        seo {
          metaTitle
          metaDescription
          metaImage {
            publicURL
          }
        }
        mobileBackground {
          publicURL
          childImageSharp {
            gatsbyImageData(height: 1000, width: 1000, layout: FIXED)
          }
        }
        desktopBackground {
          publicURL
          childImageSharp {
            gatsbyImageData(height: 1000, width: 1000, layout: FIXED)
          }
        }
      }
    }
    books: allMarkdownRemark(filter: {frontmatter: {template: {eq: "book"}, homepage: {eq: true}}}) {
      nodes {
        id
        frontmatter {
          title
          tags
          description
          image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                transformOptions: {fit: COVER}
                placeholder: BLURRED
              )
            }
          }
          imageAlt
          slug
        }
      }
    }
  }
`

Homepage.propTypes = {
  data: PropTypes.object,
}

export default Homepage
